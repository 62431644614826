import { DatePipe } from '@angular/common'
import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core'

import { Notification } from '@notifications/models/notifications'
import { NotificationModalService } from '@notifications/services/notification-modal.service'
import { NotificationsService } from '@notifications/services/notifications.service'

@Component({
  selector: 'app-notification',
  standalone: true,
  imports: [DatePipe],
  templateUrl: './notification.component.html',
  styleUrl: './notification.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationComponent {
  notificationsService = inject(NotificationsService)
  notificationModalService = inject(NotificationModalService)

  notification = input.required<Notification>()

  async openModal() {
    this.notificationModalService.openNotificationModal(this.notification(), false)
  }
}
